import React, {Component, Fragment} from 'react';
import './ReportsSideMenu.css';
import IconOutline from './152-column-chart-arrow-growth-outline.json'
import { motion } from "framer-motion"
import { Link } from "react-router-dom";
import MaterialIcon from 'material-icons-react';

export default class ReportsSideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStopped: true,
            isPaused: true,
            menuActive: false,            
        }
    }
    
    animationStart = () => {
        this.setState({
            isStopped: false,
            isPaused: false
        });
    }
    
    animationStop = () => {
        this.setState({
            isStopped: true,
            isPaused: true
        });        
    }

    toggleMenu = () => {
        this.setState({menuActive: !this.state.menuActive})
    }

    render() {
        let menuClass = this.state.menuActive ? 'nsmi_submenu_open' : 'nsmi_submenu_close'
        const list = {
            visible: {
              opacity: 1,
              transition: {
                when: "beforeChildren",
                staggerChildren: 0.01,
                delay: 0.01
              } 
            },
            hidden: { 
              opacity: 0,
              transition: {
                when: "afterChildren",
              } 
            },
          }

          const item = {
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: -5 },
          }


        return(
            <div
                className={"nsmi_home menu-item-home menu-item "+(this.props.activeitem === 'report'? ' active' : '') }
                onMouseEnter={this.animationStart}
                onMouseLeave={this.animationStop}>
                <div className={"nsmi_menu_wrapper d-flex "+ menuClass} onClick={this.toggleMenu}>
                    <div className="nsmi_menu-icon" >
                        <MaterialIcon icon="leaderboard" />
                    </div>                
                    <p className="nsmi_menu_name">Reports</p>
                    <div className="menu_icon_arrow ml-auto"></div>
                </div>
                {
                    this.state.menuActive &&
                        (
                            <motion.ul 
                                className="nsmi_submenu " 
                                initial="hidden"
                                animate="visible"
                                variants={list}>
                                {   (this.props.myprivileges.includes('access_monthly_overview')) ?
                                    (<Fragment>
                                        <Link to={`/reports/log-timer?uuid=${this.props.logger}`} > <motion.li variants={item}>Daily Logs</motion.li></Link>                                        
                                        {this.props.myprivileges.includes('project_logs')?
                                        (
                                            <>
                                                <Link to={`/reports/projectwise?uuid=${this.props.logger}`} ><motion.li variants={item}>Project Logs</motion.li></Link>
                                                <Link to={`/reports/project-work-log?uuid=${this.props.logger}`}><motion.li variants={item}>Hours for Client</motion.li></Link>
                                                <Link to={`/reports/over-limit?uuid=${this.props.logger}`}><motion.li variants={item}>Overlimit</motion.li></Link>
                                            </>
                                        )
                                        :
                                        null
                                        }
                                        {this.props.myprivileges.includes('online_availability_report') ?
                                            (
                                            <>
                                                <Link to={`/report/online-availability?uuid=${this.props.logger}`} ><motion.li variants={item}>Online Availability</motion.li></Link>
                                                <Link to={`/report/qrreport?uuid=${this.props.logger}`}  ><motion.li variants={item}>Questionnaire Report</motion.li></Link>
                                            </>
                                            )
                                            :
                                            null
                                         } 
                                        {this.props.myprivileges.includes('show_active_trello_boards') &&
                                        (
                                        <>
                                            {this.props.myprivileges.includes('health_report') &&
                                            (
                                                <>
                                                    <Link to={`/client-health-report?uuid=${this.props.logger}`}><motion.li variants={item}>Client Health Report</motion.li></Link>
                                                </>
                                            )}
                                            <Link to={`/reports/active-trello-boards?uuid=${this.props.logger}`}><motion.li variants={item}>Active Trello Boards</motion.li></Link>
                                            <Link to={`/reports/client-ratio?uuid=${this.props.logger}`}><motion.li variants={item}>Client - Designer Ratio</motion.li></Link>
                                        </>
                                        )
                                        }

                                        <Link to={`/reports/monthly-overview?uuid=${this.props.logger}`}><motion.li variants={item}>Monthly Overview</motion.li></Link>
                                        {this.props.myprivileges.includes('kimp_subscription_count_access') &&
                                        (
                                            <Link to={`/countdown?uuid=${this.props.logger}`}><motion.li variants={item}>Actual Subscriptions</motion.li></Link>                                                                           
                                        )}
                                        {this.props.myprivileges.includes('closed_trello_board_access') &&
                                        (
                                            <Link to={`/reports/closed-trello-boards?uuid=${this.props.logger}`}><motion.li variants={item}>Closed Trello Boards</motion.li></Link>                                                                           
                                        )}
                                        
                                     </Fragment>
                                    )
                                    :
                                    (
                                       <Fragment>
                                        <Link to={`/reports/log-timer?uuid=${this.props.logger}`}> <motion.li variants={item}>Daily Logs</motion.li></Link>
                                         {this.props.myprivileges.includes('project_logs')?
                                            (
                                                <>
                                                    <Link to={`/reports/projectwise?uuid=${this.props.logger}`} ><motion.li variants={item}>Project Logs</motion.li></Link>
                                                    <Link to={`/reports/project-work-log?uuid=${this.props.logger}`}><motion.li variants={item}>Hours for Client</motion.li></Link>
                                                    <Link to={`/reports/over-limit?uuid=${this.props.logger}`}><motion.li variants={item}>Overlimit</motion.li></Link>
                                                </>
                                            )
                                            :
                                            null
                                         } 
                                         {this.props.myprivileges.includes('online_availability_report') ?
                                            (
                                            <>
                                                <Link to={`/report/online-availability?uuid=${this.props.logger}`}><motion.li variants={item}>Online Availability</motion.li></Link>
                                                <Link to={`/report/qrreport?uuid=${this.props.logger}`}><motion.li variants={item}>Questionnaire Report</motion.li></Link>
                                            </>
                                            )
                                            :
                                            null
                                         }   
                                         {this.props.myprivileges.includes('show_active_trello_boards') &&
                                            (
                                            <>
                                                {this.props.myprivileges.includes('health_report') &&
                                                (
                                                    <>
                                                        <Link to={`/client-health-report?uuid=${this.props.logger}`}><motion.li variants={item}>Client Health Report</motion.li></Link>
                                                    </>
                                                )}
                                                <Link to={`/reports/active-trello-boards?uuid=${this.props.logger}`} ><motion.li variants={item}>Active Trello Boards</motion.li></Link>
                                                <Link to={`/reports/client-ratio?uuid=${this.props.logger}`}><motion.li variants={item}>Client - Designer Ratio</motion.li></Link>
                                            </>
                                            )
                                         }
                                        {this.props.myprivileges.includes('kimp_subscription_count_access') &&
                                        (
                                            <Link to={`/countdown?uuid=${this.props.logger}`}><motion.li variants={item}>Actual Subscriptions</motion.li></Link>                                                                           
                                        )}
                                        {this.props.myprivileges.includes('closed_trello_board_access') &&
                                        (
                                            <Link to={`/reports/closed-trello-boards?uuid=${this.props.logger}`} ><motion.li variants={item}>Closed Trello Boards</motion.li></Link>                                                                           
                                        )}
                                         
                                     </Fragment> 
                                    )
                                }
                                {this.props.myprivileges.includes('leave_book_access') && (
                                    <Link to={`/reports/employee-leave-balance?uuid=${this.props.logger}`}><motion.li variants={item}>Leave Book</motion.li></Link>                            
                                )}
                                 {(this.props.myprivileges.includes('i_am_pl') || this.props.myprivileges.includes('i_am_com') || this.props.myprivileges.includes('i_am_hr') 
                                 || this.props.myprivileges.includes('i_am_admin') || this.props.myprivileges.includes('i_am_hradmin') || this.props.myprivileges.includes('i_am_operations') )&& (
                                    <Link to={`/reports/latecoming?uuid=${this.props.logger}`}><motion.li variants={item}>Late Coming</motion.li></Link>                            
                                )}
                                {(this.props.myprivileges.includes('i_am_pl') || this.props.myprivileges.includes('i_am_com') || this.props.myprivileges.includes('i_am_hr') 
                                 || this.props.myprivileges.includes('i_am_admin') || this.props.myprivileges.includes('i_am_hradmin') || this.props.myprivileges.includes('i_am_operations') || this.props.myprivileges.includes('i_am_qa') )&& (
                                    <Link to={`/reports/taskissues?uuid=${this.props.logger}`}><motion.li variants={item}>Task Issues Reported</motion.li></Link>                            
                                )}
                                 {(this.props.myprivileges.includes('i_am_pl') || this.props.myprivileges.includes('i_am_com') || this.props.myprivileges.includes('i_am_hr') 
                                 || this.props.myprivileges.includes('i_am_admin') )&& (
                                    <Link to={`/reports/liveactivity-designers?uuid=${this.props.logger}`}><motion.li variants={item}>Live Data Designers</motion.li></Link>                            
                                )}

                                {(this.props.myprivileges.includes('i_am_admin') || this.props.myprivileges.includes('i_am_portfolio_admin')) &&
                                (
                                    <Link to={`/portfolio-assets?uuid=${this.props.logger}`} ><motion.li variants={item}>Portfolio Assets</motion.li></Link>
                                )} 
                                <Link to={`/performance-report?uuid=${this.props.logger}`} > <motion.li variants={item}>Performance Report</motion.li></Link>    
                            </motion.ul>
                        )
                }
            </div>
        )
    }
}