import React, { Component, Fragment } from 'react';
import './header.css';
import { Container,Row,Col,Button,Popover,PopoverBody} 
from 'reactstrap';
import { Link } from "react-router-dom";
import Clock from 'react-live-clock';
import {urls} from "../../services/UrlList";
import "../../components/common/Routes";
//import TopHeader from "../topheaderBar/topheaderBar";
import {isMobile} from 'react-device-detect';
// import DarkModeToggle from '../Darktheme/Darkmodetoggle';
import BellNotification from './Notification';
import KimpLogo from "../../images/Kimp_NewLogo.svg"
import { ExpandMore, PermIdentity, ExitToApp, AssessmentOutlined } from '@material-ui/icons';
import TrackingCode from "../common/TrackingCode";
import AlertMessages from './alertMessage'
import FilterSearch from "../bcmodule/FilterSearch";
import { AccessCheck, AccessCheckBlock } from '../bcmodule/AccessCheck';


class Header extends Component { 
  
  constructor( props ) {
    super(props);
    this.state = {
      myName:'',
      showPeople:false,
      showPeopleType:'',
      myPrivileges:[],
      myId:'',
      myTimezone:'',
      isMobileView:false,  
      nCount:this.props.ncount,
      popoverOpen: false,
      randomNum: Date.now(),
      show: true,
      showMessage:true,
      trackingEnabled:false
    };
    this.toggle = this.toggle.bind(this);
    window.addEventListener("storage", this.shiftChecking);
  }

  shiftChecking = () => {
    if(localStorage.getItem("invalidshift") == '0')
    {
       this.setState({showMessage:false});
    }else if(localStorage.getItem("invalidshift") == '1')
    {
      this.setState({showMessage:true});
    }
}

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }
  handleClick() {
    this.setState({
      show: !this.state.show
    });
  }

  componentDidMount =() =>
  {
    this.checkUserPrivilege();
    const _this=this;
    setTimeout(() => {
      _this.renderContentDevice();
    }, 100);
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:true});
    } 
    

  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        //localStorage.clear();
        this.clearLocalStorage();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");   
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
             // localStorage.clear();
             this.clearLocalStorage();
              this.props.history.push("/");
            })
            .catch(error => {
              //localStorage.clear();
              this.clearLocalStorage();
              this.props.history.push("/");
            });
       
      });
  };

  clearLocalStorage = ()=>
  {
    localStorage.removeItem('khubexpire'); 
    localStorage.removeItem('user'); 
    localStorage.removeItem('firstlogin'); 
    localStorage.removeItem('superadmin');
  }
  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("khubexpire")< new Date().getTime())
    {
      console.log("entered here");
      localStorage.setItem('lastVisitedPage', window.location.pathname);
      this.signOut();
    }
    if(localStorage.getItem("user") !== null)
    {     
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges });
      if(login.privileges.includes('access_all_reports'))
      {
        this.setState({showPeople:true, showPeopleType:1, myName:login.name, myId: login.uid, myTimezone: login.my_timezone });
      }else if(login.privileges.includes('access_team_reports'))
      {
        this.setState({showPeople:true, showPeopleType:2, myName:login.name, myId: login.uid, myTimezone: login.my_timezone});
      }else
      {
        this.setState({showPeople:false, showPeopleType:3, myName:login.name, myId: login.uid, myTimezone: login.my_timezone});
      }

      if(login.trackingenabled == 1)
      {
        this.setState({trackingEnabled:true});
        console.log("tracking enabled");
      }
    }
  };


  handleSignout = () =>
  {       
    this.props.logout();
  }

  toggleSidebar = () => 
  {   
    this.props.onmenuaction();
    this.setState({isMobileView: !this.state.isMobileView});
  }

  render(){
 
    return (
      <Fragment>
        {/* {(this.props.location.pathname !='/mytasks' && this.state.myPrivileges.includes('i_am_designer') && this.props.quickactionblock) &&
        (
          <TopHeader quickaction={this.props.quickactionblock} />  
        )} */}
        <header  className={ this.props.location.pathname !='/mytasks' ? this.props.classname+" main-header-cls" : this.props.classname }>
        
        <div className="doto_header"> 
      
              {/* <button  onClick={this.state.isMobileView != 1 && this.toggleSidebar} menuTrigger={this.props.menuTrigger}>
                 <MaterialIcon icon="menu"/>
               </button>  */}
           

        <div className="menuicon_box">
        <button  onClick={this.toggleSidebar} menuTrigger={this.props.menuTrigger} className={this.state.isMobileView === true && 'menuicon_box_active'}>
                        {this.state.isMobileView ? (
                          <span className="menuclose"></span>
                        ): (
                          <span className="menuicon"></span>
                          
                        )}
                      
                      </button>
                      
        </div>

          {( this.state.myId && this.state.trackingEnabled) &&
          ( 
            <TrackingCode />
          )}
          <Container fluid>
            
            <Row>
              {/* <Col xs={6} md={0} className="d-lg-none  d-xl-none d-md-none d-sm-none">
                <div className="logo-container d-flex">
                  {/* <img onClick={this.state.isMobileView != 1 && this.toggleSidebar} menuTrigger={this.props.menuTrigger} className="doto-logo" src={LogoImg} width="282" height="76" alt="Brand" />
                </div>
              </Col> */}

              <Col xs="5" md={5} lg={5} className="d-sm-block">
                {this.state.myTimezone != '' &&
                (
                  <div className="welcome-time-box d-flex">
                    <div className="nsm_brand_logo"> <Link to={`/home?uuid=${this.state.myId}`}> <img src={KimpLogo} alt="Brand Logo"  onClick={this.hideMenu}/></Link></div>
                    <div className="welcome-message">
                     <span>Welcome </span> {this.state.myName} 👋
                  </div>
                  <span className="space space-12"></span>
                </div>
                )}
              </Col>
              <Col xs="7" md={7} lg={7}>
              <div className="header_rightbox">
                <div className="header_datentime">
                    <span className="month">
                      <Clock
                        format={'MMM'}
                        ticking={true}
                        timezone={this.state.myTimezone} />
                        .&nbsp;
                    </span>
                    <span className="date">
                      <Clock
                        format={'D'}
                        ticking={true}
                        timezone={this.state.myTimezone} />
                        .&nbsp;
                    </span>
                    <span className="year">
                      <Clock
                        format={'YYYY'}
                        ticking={true}
                        timezone={this.state.myTimezone} />
                    </span>
                    <span className="sprate_border">|</span>
                    <span className="time">
                      <Clock
                        format={'h:mm:ss A'}
                        ticking={true}
                        timezone={this.state.myTimezone} />
                    </span>
                    </div>
                    {/* <InternaltimerButton/> */} 
                    <div className="header_search">
                    {AccessCheckBlock('use_projects_search_addon') && (<FilterSearch myId={this.state.myId} />)}
                    </div>
                    
                    <div className="user-cont">
                    <BellNotification ncount={this.props.ncount} myid={this.state.myId} />
                    <div className="header_dropdown">
                    <Button id="Popover2" type="button">
                      <img src={urls.storageUrl+"storage/profile/image/"+this.state.myId+".jpg?"+this.state.randomNum}/> 
                      <span className="usename">{this.state.myName}  </span>
                      <ExpandMore/>
                    </Button>
                    <Popover
                      placement="bottom"
                      isOpen={this.state.popoverOpen}
                      target="Popover2"
                      toggle={this.toggle}
                      
                    >
                      <PopoverBody>
                      <Link to={`/my-profile?uuid=${this.state.myId}`}><PermIdentity/>My Profile</Link>
                      <Link to={`/performance-dashboard?uuid=${this.state.myId}`}><AssessmentOutlined/>My Performance</Link>
                      <span className='logout-me' onClick={this.handleSignout}><ExitToApp/> Logout</span>
                     </PopoverBody>
                    </Popover>
                    </div>
                    


                    {/* <DarkModeToggle /> */}
                  </div>
                </div>
                
            
             
              </Col>
            </Row>
            <Row className="d-block d-sm-none mobile_time_header">
              <Col sm={12}>
                <div className="welcome-time-box">
                  <p className="mb-0">
                    <span className="welcome-message">
                      Welcome {this.state.myName}
                    </span>
                    <span className="space space-12"></span>
                    <span className="time text-right float-right">
                      <Clock
                        format={'MMM.D.YYYY h:mm:ss A'}
                        ticking={true}
                        timezone={this.state.myTimezone} />
                    </span>
                    </p>
                </div>
              </Col>
            </Row>
            
          </Container>      
          </div>
          {(localStorage.getItem("invalidshift") == '1' && this.state.showMessage && !this.state.myPrivileges.includes('co_founder_only')) &&
          (
            <AlertMessages 
            message={"If you are working today, please start your shift on Kimp Hub."}/>
          )}
          {(localStorage.getItem("invalidshift") == '2' && this.state.showMessage && !this.state.myPrivileges.includes('co_founder_only') ) &&
          (
            <AlertMessages 
            message={"Action Required: Your previous day's shift was not ended on Kimp Hub. Kindly review."}/>
          )}
        </header>
      </Fragment>
    )
  }
}
export default Header;
